<template>
  <v-container
    id="calendar"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="event"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[
            { text: 'Ваш центр', to: '/' },
            { text: 'Расписание преподавателей', to: '/timeplan' },
            {
              text: event.event_date
                ? event.event_date + ' ' + event.begintime + ' ' + event.comment
                : 'Новое событие'
            }
          ]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="mx-auto"
      >
        <v-card
          max-width="700"
          class="mx-auto"
        >
          <v-card-title>
            {{ title }}

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="eventDialog = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="body-1 text-center">
            <v-form
              ref="form"
              class="mt-5"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.event_date"
                    label="Дата события"
                    :disabled="
                      event.periodic == 2 || (!event.id && event.periodic == 1)
                    "
                    :error-messages="fieldError('event_date')"
                    type="date"
                    nsuffix="PST"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.begintime"
                    label="Время начала"
                    :disabled="
                      event.periodic == 2 || (!event.id && event.periodic == 1)
                    "
                    :error-messages="fieldError('begintime')"
                    type="time"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.endtime"
                    label="Время окончания"
                    :disabled="
                      event.periodic == 2 || (!event.id && event.periodic == 1)
                    "
                    :error-messages="fieldError('endtime')"
                    type="time"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="event.periodic"
                    :error-messages="fieldError('periodic')"
                    :disabled="event.periodic == 2"
                    label="Периодическое событие"
                    :false-value="0"
                    :true-value="1"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.interval"
                    label="Интервал периодичности"
                    :disabled="event.periodic != 1"
                    :error-messages="fieldError('interval')"
                    color="secondary"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-select
                    v-model="event.interval_type"
                    :error-messages="fieldError('interval_type')"
                    label="Тип периода"
                    :disabled="event.periodic != 1"
                    :items="interval_types"
                    item-value="id"
                    item-text="name"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.period_end"
                    label="Дата окончания переодического события"
                    :disabled="event.periodic != 1"
                    :error-messages="fieldError('period_end')"
                    type="date"
                    nsuffix="PST"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-autocomplete
                    v-model="event.teacher_id"
                    :error-messages="fieldError('teacher_id')"
                    label="Преподаватель"
                    item-value="id"
                    item-text="name"
                    :items="teachers"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-autocomplete
                    v-model="event.student_id"
                    clearable
                    :error-messages="fieldError('student_id')"
                    label="Клиент"
                    item-value="id"
                    item-text="name"
                    :items="students"
                    :return-object="false"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.comment"
                    required
                    label="Название события"
                    :error-messages="fieldError('comment')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="event.occupied"
                    :error-messages="fieldError('occupied')"
                    label="Занятость события"
                    :false-value="0"
                    :true-value="1"
                  />
                  <!--v-checkbox
                      v-model="event.status"
                      :error-messages="fieldError('status')"
                      label="Активно"
                      :false-value="0"
                      :true-value="1"
                    /-->
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="event.notify"
                    :error-messages="fieldError('notify')"
                    label="Напомнить о событии"
                    :false-value="0"
                    :true-value="1"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-select
                    v-model="event.timeplanresult_id"
                    :error-messages="fieldError('timeplanresult_id')"
                    label="Результат события"
                    :items="timeplanresults"
                    item-value="tag"
                    item-text="name"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="event.result_comment"
                    label="Комментарий к результату события"
                    :error-messages="fieldError('result_comment')"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-btn
              class="ma-1"
              color="secondary"
              rounded
              min-width="100"
              :disabled="loading"
              @click="$router.push('/timeplan')"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Назад
            </v-btn>
            <v-btn
              v-if="event.id"
              color="error"
              rounded
              class="ma-1"
              min-width="100"
              title="Переместить в архив"
              :disabled="loading"
              @click="confirmDialog = true"
            >
              Удалить (в архив)
            </v-btn>
            <v-btn
              class="ma-1"
              color="secondary"
              rounded
              min-width="100"
              :disabled="loading"
              @click="save()"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              Сохранить
            </v-btn>
          <base-material-alert
            v-if="error"
            class="ma-4"
            dark
            color="error"
            dismissible
          >
            {{ error }}
          </base-material-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить запись?
        </v-card-title>

        <v-card-text> Запись будет удалена (перемещена в архив). </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="archive()"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import teachersApi from '../services/TeachersApi'
  import clientsApi from '../services/ClientsApi'
  import timeplanApi from '../services/TimeplanApi'

  export default {
    filters: {
      formatDate: function (date) {
        return moment(date).format('YYYY-MM-DD')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('YYYY-MM-DD HH:mm')
      },
    },
    // props: ["id"],
    data: function () {
      return {
        confirmDialog: false,
        event_def: {
          event_date: null,
          begintime: null,
          endtime: null,
          teacher_id: null,
          occupied: null,
          student_id: null,
          comment: null,
          timeplanresult_id: null,
          result_comment: null,
          notify: null,
          periodic: null,
          interval: null,
          interval_type: null,
          period_end: null,
        },
        event: {},
        event_datemenu: false,
        period_endmenu: false,
        interval_types: [
          { id: 1, short: 'y', key: 'years', name: 'Год' },
          { id: 2, short: 'Q', key: 'quarters', name: 'Квартал' },
          { id: 3, short: 'M', key: 'months', name: 'Месяц' },
          { id: 4, short: 'w', key: 'weeks', name: 'Неделя' },
          { id: 5, short: 'd', key: 'days', name: 'День' },
        ],

        focus: null,
        title: '',
        type: 'month',
        typeToLabel: {
          month: 'Месяц',
          week: 'Неделя',
          day: 'День',
        },
        events: [],
        teachers: [],
        students: [],
        timeplanresults: [],
        teacher: null,
        error: null,
        loading: false,

        eventDialog: false,

        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter((field) => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },
    computed: {},
    mounted () {
      document.title = 'Учебная сетка | ' + process.env.VUE_APP_NAME
      moment.locale('ru')

      if (this.$route.query.id) {
        this.loadEvent(this.$route.query.id)
        this.title = 'Редактирование события'
      } else if (this.$route.params && this.$route.params.event_date) {
        this.event = this.$route.params
        this.title =
          this.event.periodic == 2
            ? 'Результат периодического события'
            : 'Добавление нового события'
      }

      teachersApi
        .fetchActiveTeachers()
        .then((response) => {
          this.teachers = response
        })
        .catch((error) => {
          this.error = this.pretty(error.response.data)
        })

      clientsApi
        .fetchClientsLookup()
        .then((response) => {
          this.students = response
        })
        .catch((error) => {
          this.error = this.pretty(error.response.data)
        })
    },
    methods: {
      loadEvent (id) {
        timeplanApi
          .fetchEvent(id)
          .then((response) => {
            //! check errors
            this.event = response
          })
          .catch((error) => {
            alert(error)
          })
      },

      save () {
        this.error = null
        this.loading = true
        timeplanApi
          .saveEvent(this.event)
          .then((response) => {
            this.event = response
            this.eventDialog = false
            this.loading = false
          })
          .catch((error) => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
              this.error = 'Ошибка! Данные не сохранены!'
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      archive () {
        this.loading = true
        timeplanApi
          .saveEvent({ id: this.event.id, status: 0 })
          .then((response) => {
            this.loading = false
            this.$router.push('/timeplan')
          })
          .catch((error) => {
            let data = error.response.data
            this.error = this.pretty(data)
            this.loading = false
          })
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
    },
  }
</script>
