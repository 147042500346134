import axios from 'axios'

export default {
  fetchEventsBy (start, end, teacherId) {
    let query = 'from_date=' + start + '&to_date=' + end
    if (teacherId) query += '&teacher_id=' + teacherId
    return axios.get('timeplans?' + query).then(response => {
      return response.data
    })
  },

  /*
  fetchEventsByTeacher (teacherId) {
    return axios.get('timeplans?expand=timetable,clients,clientsCount,room,schedule&teacher_id=' + teacherId).then(response => {
      return response.data
    })
  },

  fetchEventsByTeacherAndDates (teacherId, start, end) {
    return axios.get('timeplans?expand=timetable,clients,clientsCount,room,schedule&teacher_id=' + teacherId + '&from_date=' + start + '&to_date=' + end).then(response => {
      return response.data
    })
  },
*/
  updateEvent (event) {
    return axios.put('timeplans/' + event.id, event).then(response => {
      return response.data
    })
  },

  createEvent (event) {
    return axios.post('timeplans', event).then(response => {
      return response.data
    })
  },

  saveEvent (event) {
    return event.id ? this.updateEvent(event) : this.createEvent(event)
  },

  deleteEvent (id) {
    return axios.delete('timeplans/' + id).then(response => {
      return response.data
    })
  },

  fetchEvent (id) {
    return axios.get('timeplans/' + id)
      .then(response => {
        return response.data
      })
  },
}
