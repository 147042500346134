var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "calendar", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _vm.event
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Расписание преподавателей", to: "/timeplan" },
                        {
                          text: _vm.event.event_date
                            ? _vm.event.event_date +
                              " " +
                              _vm.event.begintime +
                              " " +
                              _vm.event.comment
                            : "Новое событие"
                        }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { "max-width": "700" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.title) + "\n\n          "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-icon",
                        {
                          attrs: { "aria-label": "Close" },
                          on: {
                            click: function($event) {
                              _vm.eventDialog = false
                            }
                          }
                        },
                        [_vm._v("\n            mdi-close\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "body-1 text-center" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Дата события",
                                      disabled:
                                        _vm.event.periodic == 2 ||
                                        (!_vm.event.id &&
                                          _vm.event.periodic == 1),
                                      "error-messages": _vm.fieldError(
                                        "event_date"
                                      ),
                                      type: "date",
                                      nsuffix: "PST"
                                    },
                                    model: {
                                      value: _vm.event.event_date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "event_date", $$v)
                                      },
                                      expression: "event.event_date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Время начала",
                                      disabled:
                                        _vm.event.periodic == 2 ||
                                        (!_vm.event.id &&
                                          _vm.event.periodic == 1),
                                      "error-messages": _vm.fieldError(
                                        "begintime"
                                      ),
                                      type: "time"
                                    },
                                    model: {
                                      value: _vm.event.begintime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "begintime", $$v)
                                      },
                                      expression: "event.begintime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Время окончания",
                                      disabled:
                                        _vm.event.periodic == 2 ||
                                        (!_vm.event.id &&
                                          _vm.event.periodic == 1),
                                      "error-messages": _vm.fieldError(
                                        "endtime"
                                      ),
                                      type: "time"
                                    },
                                    model: {
                                      value: _vm.event.endtime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "endtime", $$v)
                                      },
                                      expression: "event.endtime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "periodic"
                                      ),
                                      disabled: _vm.event.periodic == 2,
                                      label: "Периодическое событие",
                                      "false-value": 0,
                                      "true-value": 1
                                    },
                                    model: {
                                      value: _vm.event.periodic,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "periodic", $$v)
                                      },
                                      expression: "event.periodic"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Интервал периодичности",
                                      disabled: _vm.event.periodic != 1,
                                      "error-messages": _vm.fieldError(
                                        "interval"
                                      ),
                                      color: "secondary",
                                      type: "number"
                                    },
                                    model: {
                                      value: _vm.event.interval,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "interval", $$v)
                                      },
                                      expression: "event.interval"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "interval_type"
                                      ),
                                      label: "Тип периода",
                                      disabled: _vm.event.periodic != 1,
                                      items: _vm.interval_types,
                                      "item-value": "id",
                                      "item-text": "name"
                                    },
                                    model: {
                                      value: _vm.event.interval_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.event,
                                          "interval_type",
                                          $$v
                                        )
                                      },
                                      expression: "event.interval_type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Дата окончания переодического события",
                                      disabled: _vm.event.periodic != 1,
                                      "error-messages": _vm.fieldError(
                                        "period_end"
                                      ),
                                      type: "date",
                                      nsuffix: "PST"
                                    },
                                    model: {
                                      value: _vm.event.period_end,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "period_end", $$v)
                                      },
                                      expression: "event.period_end"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "teacher_id"
                                      ),
                                      label: "Преподаватель",
                                      "item-value": "id",
                                      "item-text": "name",
                                      items: _vm.teachers,
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.event.teacher_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "teacher_id", $$v)
                                      },
                                      expression: "event.teacher_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      clearable: "",
                                      "error-messages": _vm.fieldError(
                                        "student_id"
                                      ),
                                      label: "Клиент",
                                      "item-value": "id",
                                      "item-text": "name",
                                      items: _vm.students,
                                      "return-object": false
                                    },
                                    model: {
                                      value: _vm.event.student_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "student_id", $$v)
                                      },
                                      expression: "event.student_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Название события",
                                      "error-messages": _vm.fieldError(
                                        "comment"
                                      )
                                    },
                                    model: {
                                      value: _vm.event.comment,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "comment", $$v)
                                      },
                                      expression: "event.comment"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "occupied"
                                      ),
                                      label: "Занятость события",
                                      "false-value": 0,
                                      "true-value": 1
                                    },
                                    model: {
                                      value: _vm.event.occupied,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "occupied", $$v)
                                      },
                                      expression: "event.occupied"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "notify"
                                      ),
                                      label: "Напомнить о событии",
                                      "false-value": 0,
                                      "true-value": 1
                                    },
                                    model: {
                                      value: _vm.event.notify,
                                      callback: function($$v) {
                                        _vm.$set(_vm.event, "notify", $$v)
                                      },
                                      expression: "event.notify"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "timeplanresult_id"
                                      ),
                                      label: "Результат события",
                                      items: _vm.timeplanresults,
                                      "item-value": "tag",
                                      "item-text": "name"
                                    },
                                    model: {
                                      value: _vm.event.timeplanresult_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.event,
                                          "timeplanresult_id",
                                          $$v
                                        )
                                      },
                                      expression: "event.timeplanresult_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Комментарий к результату события",
                                      "error-messages": _vm.fieldError(
                                        "result_comment"
                                      )
                                    },
                                    model: {
                                      value: _vm.event.result_comment,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.event,
                                          "result_comment",
                                          $$v
                                        )
                                      },
                                      expression: "event.result_comment"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            color: "secondary",
                            rounded: "",
                            "min-width": "100",
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/timeplan")
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n              mdi-arrow-left\n            "
                            )
                          ]),
                          _vm._v("\n            Назад\n          ")
                        ],
                        1
                      ),
                      _vm.event.id
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                color: "error",
                                rounded: "",
                                "min-width": "100",
                                title: "Переместить в архив",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  _vm.confirmDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            Удалить (в архив)\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            color: "secondary",
                            rounded: "",
                            "min-width": "100",
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("\n              mdi-check\n            ")
                          ]),
                          _vm._v("\n            Сохранить\n          ")
                        ],
                        1
                      ),
                      _vm.error
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "ma-4",
                              attrs: {
                                dark: "",
                                color: "error",
                                dismissible: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.error) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить запись?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(" Запись будет удалена (перемещена в архив). ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.archive()
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }